import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Home.css";
import homePageGif from "../../../../assets/gifs/HomePageGif.gif";
import headerImage from "../../../../assets/images/Minimove-Header.png";

const MiniMoveHome = () => {
  const navigate = useNavigate();
  const { eventName } = useParams();

  const goToNextPage = () => {
    navigate(`/${eventName}/face-gallery`);
  };

  return (
    <div className="home-container">
      <div className="home-title">
        <img src={headerImage} alt="MiniMove" className="header-image" />
      </div>
      <div className="gif-container">
        <img src={homePageGif} alt="MiniMove Animation" />
      </div>
      <div className="start-button-container">
        <button className="start-button" onClick={goToNextPage}>
          התחל
        </button>
      </div>
    </div>
  );
};

export default MiniMoveHome;
