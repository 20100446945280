import React, { createContext, useState, useContext } from "react";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [userId, setUserId] = useState(null);
  const [eventName, setEventName] = useState();

  return (
    <AppContext.Provider
      value={{
        images,
        setImages,
        selectedImages,
        setSelectedImages,
        totalValue,
        setTotalValue,
        userId,
        setUserId,
        eventName,
        setEventName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
