const DB_NAME = "ImageDatabase";
const DB_VERSION = 3;
const FACE_STORE_NAME = "FaceImages";
const BODY_STORE_NAME = "BodyImages";
const LAST_UPDATE_STORE_NAME = "LastUpdate";

export const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(FACE_STORE_NAME)) {
        db.createObjectStore(FACE_STORE_NAME, { keyPath: "path" });
      }
      if (!db.objectStoreNames.contains(BODY_STORE_NAME)) {
        db.createObjectStore(BODY_STORE_NAME, { keyPath: "path" });
      }
      if (!db.objectStoreNames.contains(LAST_UPDATE_STORE_NAME)) {
        db.createObjectStore(LAST_UPDATE_STORE_NAME, { keyPath: "storeName" });
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      console.error("Error opening database:", event.target.errorCode);
      reject(event.target.errorCode);
    };
  });
};

export const updateLastUpdateTime = async (storeName) => {
  const db = await openDatabase();
  const transaction = db.transaction([LAST_UPDATE_STORE_NAME], "readwrite");
  const store = transaction.objectStore(LAST_UPDATE_STORE_NAME);
  const timestamp = new Date().toISOString();
  store.put({ storeName, timestamp });
  return await new Promise((resolve, reject) => {
    transaction.oncomplete = () => {
      resolve(true);
    };
    transaction.onerror = (event) => {
      console.error(
        "Transaction error on updating time:",
        event.target.errorCode
      );
      reject(event.target.errorCode);
    };
  });
};

export const getLastUpdateTime = async (storeName) => {
  const db = await openDatabase();
  const transaction = db.transaction([LAST_UPDATE_STORE_NAME], "readonly");
  const store = transaction.objectStore(LAST_UPDATE_STORE_NAME);
  const request = store.get(storeName);
  return await new Promise((resolve, reject) => {
    request.onsuccess = (event) => {
      if (event.target.result) {
        resolve(event.target.result.timestamp);
      } else {
        resolve(null);
      }
    };
    request.onerror = (event) => {
      console.error("Error fetching last update time:", event.target.errorCode);
      reject(event.target.errorCode);
    };
  });
};

export const saveFaceImageToDB = async (path, imageData) => {
  const db = await openDatabase();
  const transaction = db.transaction(
    [FACE_STORE_NAME, LAST_UPDATE_STORE_NAME],
    "readwrite"
  );
  const store = transaction.objectStore(FACE_STORE_NAME);
  console.log("set new time face");
  store.put({ path, imageData, timestamp: new Date().toISOString() });
  await updateLastUpdateTime(FACE_STORE_NAME);
  return await new Promise((resolve, reject) => {
    transaction.oncomplete = () => {
      resolve(true);
    };
    transaction.onerror = (event) => {
      console.error(
        "Transaction error on saving face image:",
        event.target.errorCode
      );
      reject(event.target.errorCode);
    };
  });
};

export const getFaceImageFromDB = async (path) => {
  const db = await openDatabase();
  const transaction = db.transaction(FACE_STORE_NAME, "readonly");
  const store = transaction.objectStore(FACE_STORE_NAME);
  return await new Promise((resolve, reject) => {
    const request = store.get(path);
    request.onsuccess = (event) => {
      if (event.target.result) {
        resolve(event.target.result.imageData);
      } else {
        resolve(null);
      }
    };
    request.onerror = (event_1) => {
      console.error(
        "Error fetching face image from DB:",
        event_1.target.errorCode
      );
      reject(event_1.target.errorCode);
    };
  });
};

export const saveBodyImageToDB = async (path, imageData) => {
  const db = await openDatabase();
  const transaction = db.transaction(
    [BODY_STORE_NAME, LAST_UPDATE_STORE_NAME],
    "readwrite"
  );
  const store = transaction.objectStore(BODY_STORE_NAME);
  console.log("set new time body");
  store.put({ path, imageData, timestamp: new Date().toISOString() });
  await updateLastUpdateTime(BODY_STORE_NAME);
  return await new Promise((resolve, reject) => {
    transaction.oncomplete = () => {
      resolve(true);
    };
    transaction.onerror = (event) => {
      console.error(
        "Transaction error on saving body image:",
        event.target.errorCode
      );
      reject(event.target.errorCode);
    };
  });
};

export const getBodyImageFromDB = async (path) => {
  const db = await openDatabase();
  const transaction = db.transaction(BODY_STORE_NAME, "readonly");
  const store = transaction.objectStore(BODY_STORE_NAME);
  return await new Promise((resolve, reject) => {
    const request = store.get(path);
    request.onsuccess = (event) => {
      if (event.target.result) {
        resolve(event.target.result.imageData);
      } else {
        resolve(null);
      }
    };
    request.onerror = (event_1) => {
      console.error(
        "Error fetching body image from DB:",
        event_1.target.errorCode
      );
      reject(event_1.target.errorCode);
    };
  });
};

export const getAllKeysFromDB = async (storeName) => {
  const db = await openDatabase();
  const transaction = db.transaction(storeName, "readonly");
  const store = transaction.objectStore(storeName);

  return new Promise((resolve, reject) => {
    const request = store.getAllKeys();
    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
    request.onerror = (event) => {
      console.error(
        "Error fetching keys from IndexedDB:",
        event.target.errorCode
      );
      reject(event.target.errorCode);
    };
  });
};

export const deleteImageFromDB = async (storeName, path) => {
  const db = await openDatabase();
  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);
  return new Promise((resolve, reject) => {
    const request = store.delete(path);
    request.onsuccess = () => {
      resolve(true);
    };
    request.onerror = (event) => {
      console.error(
        `Error deleting image from ${storeName}:`,
        event.target.errorCode
      );
      reject(event.target.errorCode);
    };
  });
};

export const cleanUpOldEntries = async (storeName, ttl) => {
  const db = await openDatabase();
  const now = new Date();

  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);

  const allKeysRequest = store.getAllKeys();

  allKeysRequest.onsuccess = async (event) => {
    const allKeys = event.target.result;
    const cleanupPromises = allKeys.map((key) => {
      return new Promise((resolve, reject) => {
        const getRequest = store.get(key);
        getRequest.onsuccess = (e) => {
          const entry = e.target.result;
          if (entry) {
            const entryTime = new Date(entry.timestamp);
            if (now - entryTime > ttl) {
              const deleteRequest = store.delete(key);
              deleteRequest.onsuccess = () => resolve(true);
              deleteRequest.onerror = (err) => reject(err.target.errorCode);
            } else {
              resolve(true);
            }
          } else {
            resolve(false); // If no entry, resolve to false indicating nothing to delete
          }
        };
        getRequest.onerror = (err) => reject(err.target.errorCode);
      });
    });

    try {
      await Promise.all(cleanupPromises);
      console.log("Cleanup completed");
      transaction.commit();
    } catch (error) {
      console.error("Error during cleanup promises:", error);
    }
  };

  allKeysRequest.onerror = (event) => {
    console.error("Failed to fetch keys:", event.target.errorCode);
  };
};

/**
 * Deletes the entire IndexedDB database.
 * @param {string} dbName The name of the database to delete.
 */
export const deleteDatabase = async (dbName) => {
  return new Promise((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(dbName);

    deleteRequest.onsuccess = () => {
      console.log(`Database ${dbName} deleted successfully.`);
      resolve();
    };

    deleteRequest.onerror = (event) => {
      console.error(
        `Error deleting database ${dbName}:`,
        event.target.errorCode
      );
      reject(event.target.errorCode);
    };

    deleteRequest.onblocked = () => {
      console.warn(`Delete operation of database ${dbName} blocked.`);
    };
  });
};
