/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react"; // הוסף useEffect
import { Stage, Layer } from "react-konva";
import { useNavigate } from "react-router-dom";
import DraggableImage from "./components/DraggableImage";
import { Controls } from "./components/Controls";
import "./EditYourCharacter.css";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import { showAlert } from "../../../../generalUtils";
import { useLoadImages } from "./hooks/useLoadImages";
import { useImageHandlers } from "./hooks/useImageHandlers";
import { useAppContext } from "../../../../AppContext";
import { ToastContainer } from "react-toastify";
import { handleSaveAndFinish } from "./SaveAndLoad"; // Ensure this is the correct path
import moveTheHeadGif from "../../../../assets/gifs/move_the_heads_432_milk.gif";

const EditYourCharacter = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const stageRef = useRef(null);
  const { eventName } = useAppContext();
  const { showLoading, hideLoading } = useLoading();

  const [activeImage, setActiveImage] = useState(null);
  const [showGif, setShowGif] = useState(false);

  // Using the custom hook to load images
  const { images, setImages, canvasWidth, isLoadingComplete } = useLoadImages(
    serverUrl,
    stageRef,
    showLoading,
    hideLoading,
    navigate,
    showAlert
  );

  // Extract selectedFaces and selectedBodies from images state
  const selectedFaces = images
    .filter((img) => img.draggable)
    .map((img) => img.originalSrc);
  const selectedBodies = images
    .filter((img) => !img.draggable)
    .map((img) => img.originalSrc);

  // Using the custom hook for image handlers
  const { handleResize, handleFlip, handleUpdatePosition } = useImageHandlers(
    activeImage,
    setActiveImage,
    stageRef,
    images,
    setImages,
    selectedFaces,
    selectedBodies,
    serverUrl
  );

  // Calculate if all draggable heads have been moved
  const allHeadsMoved = images
    .filter((img) => img.draggable)
    .every((img) => img.hasMoved);

  useEffect(() => {
    if (!allHeadsMoved) {
      const timer = setTimeout(() => {
        setShowGif(true); // הצגת הגיף
      }, 6000);

      return () => clearTimeout(timer); // הסרת הטיימר אם התנאים השתנו
    } else {
      setShowGif(false); // הסרת הגיף אם כל הראשים זזו
    }
  }, [allHeadsMoved]);

  const footerButtons = async (name) => {
    showLoading();
    const encodedEventName = encodeURIComponent(eventName);
    if (name === "back") {
      navigate(`/${encodedEventName}/body-gallery`);
    } else if (name === "finish") {
      await handleSaveAndFinish(stageRef, setActiveImage, navigate, eventName);
    }
  };

  return (
    <div className="edit-character-container">
      <ToastContainer />
      <header className="fixed-header rtl">התאימו ראשים לתוצאה מושלמת</header>
      <div className="controls-container">
        <Controls
          handleResize={handleResize}
          handleFlip={handleFlip}
          activeImage={activeImage}
          isBodySelected={false}
        />
      </div>
      <div className="canvas-wrapper">
        <div
          className="canvas-container"
          style={{ width: canvasWidth, height: window.innerWidth }}
        >
          <Stage ref={stageRef} width={canvasWidth} height={window.innerWidth}>
            <Layer>
              {images.map((image, index) => (
                <DraggableImage
                  key={index}
                  src={image.src}
                  width={image.width}
                  height={image.height}
                  initialPosition={image.position}
                  canvasWidth={canvasWidth + 10}
                  canvasHeight={window.innerWidth}
                  isSelected={activeImage === image.src}
                  onSelect={() => setActiveImage(image.src)}
                  onUpdatePosition={(src, newPosition, hasMoved) =>
                    handleUpdatePosition(src, newPosition, hasMoved)
                  }
                  draggable={image.draggable}
                />
              ))}
            </Layer>
          </Stage>
        </div>
      </div>

      {showGif && (
        <div className="gif-container">
          <img src={moveTheHeadGif} alt="Guide GIF" className="guide-gif" />
        </div>
      )}

      <footer className="fixed-footer">
        <button className="footer-button" onClick={() => footerButtons("back")}>
          חזרה
        </button>
        <button
          className="footer-button"
          onClick={() => footerButtons("finish")}
          disabled={!isLoadingComplete || !allHeadsMoved} // Disabled until all heads are moved
        >
          סיום
        </button>
      </footer>
    </div>
  );
};

export default EditYourCharacter;
