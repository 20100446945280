export const handleResize = (
  delta,
  setImages,
  activeImage,
  stageRef,
  selectedFaces,
  selectedBodies
) => {
  if (activeImage && stageRef.current) {
    const stage = stageRef.current;
    const canvasHeight = stage.height();
    setImages((prevImages) => {
      return prevImages.map((image) => {
        if (image.src === activeImage) {
          console.log(
            `image.aspectRatio: ${image.aspectRatio}\n image.width: ${image.width}\nimage.height: ${image.height}`
          );
          const newWidth = Math.max(image.width + delta, 5);
          const newHeight = Math.max(newWidth / image.aspectRatio, 5);

          const isFace = selectedFaces.includes(image.originalSrc);
          const isBody = selectedBodies.includes(image.originalSrc);

          console.log(`isFace: ${isFace} | isBody: ${isBody}`);

          if (isFace) {
            const maxFaceSize = canvasHeight * 0.8;
            const minFaceSize = canvasHeight * 0.1;
            console.log(
              `maxFaceSize: ${maxFaceSize} | minFaceSize: ${minFaceSize}\nnewHeight: ${newHeight}`
            );
            if (
              (newHeight <= maxFaceSize && delta > 0) ||
              (newHeight >= minFaceSize && delta < 0)
            ) {
              return { ...image, width: newWidth, height: newHeight };
            } else {
              return image;
            }
          }

          if (isBody) {
            const maxBodySize = canvasHeight;
            const minBodySize = canvasHeight * 0.7;
            if (newHeight <= maxBodySize && newHeight >= minBodySize) {
              return { ...image, width: newWidth, height: newHeight };
            }
          }
        }
        return image;
      });
    });
  }
};

export const handleFlip = async (
  setActiveImage,
  activeImage,
  images,
  setImages
) => {
  if (activeImage) {
    const imageToFlip = images.find((image) => image.src === activeImage);

    if (imageToFlip) {
      const img = new Image();
      img.src = imageToFlip.src;
      img.crossOrigin = "anonymous";

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;

        const currentPosition = { ...imageToFlip.position };

        ctx.translate(img.width, 0);
        ctx.scale(-1, 1);
        ctx.drawImage(img, 0, 0);

        const flippedDataUrl = canvas.toDataURL();

        setImages((prevImages) =>
          prevImages.map((image) => {
            if (image.src === activeImage) {
              return {
                ...image,
                src: flippedDataUrl,
                flipped: !image.flipped,
                position: currentPosition,
              };
            }
            return image;
          })
        );

        setActiveImage(flippedDataUrl);
      };

      img.onerror = (error) => {
        console.error("Error loading image for flip:", error);
      };
    }
  }
};

export const handleUpdatePosition = (
  src,
  newPosition,
  hasMoved = false,
  setImages
) => {
  setImages((prevImages) =>
    prevImages.map((image) => {
      if (image.src === src) {
        return { ...image, position: newPosition, hasMoved };
      }
      return image;
    })
  );
};

export const handleZIndexChange = (direction, activeImage, stageRef) => {
  if (activeImage && stageRef.current) {
    const layer = stageRef.current.children[0];
    const konvaImage = layer.findOne(
      (node) => node.attrs.image.src === activeImage
    );

    if (konvaImage) {
      if (direction === "up") {
        konvaImage.moveUp();
      } else if (direction === "down") {
        konvaImage.moveDown();
      }
      layer.draw();
    }
  }
};
