import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./BodyGallery.css";

const MiniMoveBodyGallery = () => {
  const navigate = useNavigate();
  const { eventName } = useParams();

  const goToNextPage = () => {
    navigate(`/${eventName}/result`);
  };

  return (
    <div className="simple-container">
      <h1 className="title">גלריית גוף - MiniMove</h1>
      <button className="next-button" onClick={goToNextPage}>
        הבא
      </button>
    </div>
  );
};

export default MiniMoveBodyGallery;
