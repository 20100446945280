import React from "react";

const Modal = ({ isOpen, onClose, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target.className === "modal-overlay") {
      onCancel();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <h2 className="rtl">האם בנוסף תרצה להוריד את התמונה למכשיר?</h2>
        <div className="modal-buttons">
          <button onClick={onConfirm}>כן</button>
          <button onClick={onClose}>לא</button>
          <button onClick={onCancel}>ביטול</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
