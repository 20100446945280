import React, { useState, useEffect } from "react";
import { Image as KonvaImage } from "react-konva";
import useImage from "use-image";

const DraggableImage = ({
  src,
  width,
  height,
  initialPosition,
  canvasWidth,
  canvasHeight,
  isSelected,
  onSelect,
  onUpdatePosition,
  draggable,
}) => {
  const [image] = useImage(src);
  const [position, setPosition] = useState(initialPosition);
  const [size, setSize] = useState({ width, height });

  useEffect(() => {
    if (image) {
      const aspectRatio = image.width / image.height;
      const newHeight = size.width / aspectRatio;
      setSize({ width: size.width, height: newHeight });
    }
  }, [image, size.width]);

  useEffect(() => {
    setSize({ width, height });
  }, [width, height]);

  const handleDragEnd = (e) => {
    const newPosition = {
      x: e.target.x(),
      y: e.target.y(),
    };
    setPosition(newPosition);
    const hasMoved =
      newPosition.x !== initialPosition.x ||
      newPosition.y !== initialPosition.y;
    onUpdatePosition(src, newPosition, hasMoved);
  };

  const handleDragMove = (e) => {
    const imageNode = e.target;
    const buffer = canvasWidth * 0.025; // This is the buffer to allow slight overflow
    const newX = Math.max(
      -buffer,
      Math.min(e.target.x(), canvasWidth - imageNode.width() + buffer)
    );
    const newY = Math.max(
      0.5,
      Math.min(e.target.y(), canvasHeight - imageNode.height() + buffer)
    );
    imageNode.position({ x: newX, y: newY });
  };

  return (
    <KonvaImage
      image={image}
      x={position.x}
      y={position.y}
      width={size.width}
      height={size.height}
      draggable={draggable}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      onClick={onSelect}
      onTouchStart={onSelect}
      stroke={isSelected ? "blue" : "transparent"}
      strokeWidth={isSelected ? 1 : 0}
    />
  );
};

export default DraggableImage;
