export const organizeImages = (images, number) => {
  const imagesByValue = {
    1: [],
    2: [],
    3: [],
  };

  images.forEach((imageObj) => {
    const { path } = imageObj;
    const imageValue = parseInt(path.split("-").pop().split(".")[0][0]); // Get the first digit (tens place)
    if (imagesByValue[imageValue]) {
      imagesByValue[imageValue].push(imageObj);
    }
  });

  const organizedImages = [];
  let row = [];

  if (number === 3) {
    imagesByValue[3].forEach((imageObj) => {
      if (row.length > 0) {
        organizedImages.push(row);
        row = [];
      }
      row.push(imageObj);
      organizedImages.push(row);
      row = [];
    });

    while (imagesByValue[2].length > 0) {
      if (row.length > 0) {
        organizedImages.push(row);
        row = [];
      }

      const image2 = imagesByValue[2].shift();
      row.push(image2);

      if (imagesByValue[1].length > 0) {
        const image1 = imagesByValue[1].shift();
        row.push(image1);
      }

      organizedImages.push(row);
      row = [];
    }

    while (imagesByValue[1].length > 0) {
      row.push(imagesByValue[1].shift());
      if (row.length === 3) {
        organizedImages.push(row);
        row = [];
      }
    }
  } else if (number === 2) {
    while (imagesByValue[2].length > 0) {
      if (row.length > 0) {
        organizedImages.push(row);
        row = [];
      }

      const image2 = imagesByValue[2].shift();
      row.push(image2);

      if (imagesByValue[1].length > 0) {
        const image1 = imagesByValue[1].shift();
        row.push(image1);
      }

      organizedImages.push(row);
      row = [];
    }

    while (imagesByValue[1].length > 0) {
      row.push(imagesByValue[1].shift());
      if (row.length === 3) {
        organizedImages.push(row);
        row = [];
      }
    }

    imagesByValue[3].forEach((imageObj) => {
      if (row.length > 0) {
        organizedImages.push(row);
        row = [];
      }
      row.push(imageObj);
      organizedImages.push(row);
      row = [];
    });
  } else {
    // Sort for number === 1
    while (imagesByValue[1].length >= 3) {
      row.push(
        imagesByValue[1].shift(),
        imagesByValue[1].shift(),
        imagesByValue[1].shift()
      );
      organizedImages.push(row);
      row = [];
    }

    while (imagesByValue[2].length > 0 && imagesByValue[1].length > 0) {
      row.push(imagesByValue[2].shift(), imagesByValue[1].shift());
      organizedImages.push(row);
      row = [];
    }

    while (imagesByValue[1].length > 0) {
      row.push(imagesByValue[1].shift());
      if (row.length === 3) {
        organizedImages.push(row);
        row = [];
      }
    }

    while (imagesByValue[2].length > 0) {
      row.push(imagesByValue[2].shift());
      organizedImages.push(row);
      row = [];
    }

    imagesByValue[3].forEach((imageObj) => {
      if (row.length > 0) {
        organizedImages.push(row);
        row = [];
      }
      row.push(imageObj);
      organizedImages.push(row);
      row = [];
    });
  }

  if (row.length > 0) {
    organizedImages.push(row);
  }

  return organizedImages;
};
