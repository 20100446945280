import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const serverUrl = process.env.REACT_APP_SERVER_URL;

const Login = () => {
  const onSuccess = (credentialResponse) => {
    console.log("Login Success:", credentialResponse);
    const token = credentialResponse.credential;

    fetch(`${serverUrl}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Login successful") {
          console.log("Login successful");
          localStorage.setItem("user", JSON.stringify(data.user));
          window.location.href = "/";
        } else if (data.error === "Unauthorized user") {
          alert("אין לך הרשאה לעמוד זה");
        } else {
          console.error("Login failed", data.error);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const onFailure = (error) => {
    console.log("Login failed:", error);
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div>
        <h2>Login</h2>
        <GoogleLogin onSuccess={onSuccess} onError={onFailure} />
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
