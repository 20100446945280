import React from "react";
import "./EventNotFound.css";

const EventNotFound = () => {
  return (
    <div className="event-not-found">
      <h1>אירוע לא קיים</h1>
      <p>אנא סרוק את הברקוד המתאים</p>
    </div>
  );
};

export default EventNotFound;
