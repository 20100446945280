/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faArrowsAltH,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

export const Controls = ({
  handleResize,
  handleFlip,
  handleZIndexChange,
  activeImage,
  isBodySelected,
}) => {
  const [intervalId, setIntervalId] = useState(null);

  const startResize = (resizeAmount) => {
    if (!activeImage || isBodySelected) return;
    handleResize(resizeAmount);
    const id = setInterval(() => {
      handleResize(resizeAmount);
    }, 50);
    setIntervalId(id);
  };

  const stopResize = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  // Touch Events
  const handleTouchStart = (resizeAmount, event) => {
    event.preventDefault();
    startResize(resizeAmount);
  };

  const handleTouchEnd = (event) => {
    event.preventDefault();
    stopResize();
  };

  // Mouse Events
  const handleMouseDown = (resizeAmount) => {
    startResize(resizeAmount);
  };

  const handleMouseUp = () => {
    stopResize();
  };

  useEffect(() => {
    document.addEventListener("mouseup", stopResize);
    return () => {
      document.removeEventListener("mouseup", stopResize);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return (
    <div className="controls-container">
      <button
        className="edit-button"
        onTouchStart={(e) => handleTouchStart(1, e)}
        onTouchEnd={handleTouchEnd}
        onMouseDown={() => handleMouseDown(1)}
        onMouseUp={handleMouseUp}
        disabled={!activeImage || isBodySelected}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <button
        className="edit-button"
        onTouchStart={(e) => handleTouchStart(-2, e)}
        onTouchEnd={handleTouchEnd}
        onMouseDown={() => handleMouseDown(-2)}
        onMouseUp={handleMouseUp}
        disabled={!activeImage || isBodySelected}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <button
        className="edit-button"
        onClick={handleFlip}
        disabled={!activeImage}
      >
        <FontAwesomeIcon icon={faArrowsAltH} />
      </button>
      <button
        className="edit-button"
        onClick={() => handleZIndexChange("up")}
        // disabled={!activeImage}
        disabled={true}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
      <button
        className="edit-button"
        onClick={() => handleZIndexChange("down")}
        // disabled={!activeImage}
        disabled={true}
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </button>
    </div>
  );
};

export default Controls;
