export const PRICE_TYPES = {
    DYNO: 'dyno',
    REDIS: 'redis'
 };
 
 export const herokuPrices = {
    dynoType: {
        'eco': { 
            hour: 0.005,
            month: 5,
            compute: "1x Compute",
            ram: "256 MB RAM",
            category: "SHARED COMPUTE",
            description: "for 1,000 dyno hours per month, shared across all your Eco dynos"
        },
        'basic': { 
            hour: 0.01,
            month: 7,
            compute: "1x Compute",
            ram: "512 MB RAM",
            category: "SHARED COMPUTE"
        },
        'standard-1x': { 
            hour: 0.035,
            month: 25,
            compute: "1-4x Compute",
            ram: "512 MB RAM",
            category: "SHARED COMPUTE"
        },
        'standard-2x': { 
            hour: 0.069,
            month: 50,
            compute: "4-8x Compute",
            ram: "1 GB RAM",
            category: "SHARED COMPUTE"
        },
        'performance-m': { 
            hour: 0.347,
            month: 250,
            compute: "12x Compute",
            ram: "2.5 GB RAM",
            category: "DEDICATED COMPUTE"
        },
        'performance-l': { 
            hour: 0.694,
            month: 500,
            compute: "50x Compute",
            ram: "14 GB RAM",
            category: "DEDICATED COMPUTE",
            subtype: "COMPUTE-OPTIMIZED"
        },
        'performance-l-ram': { 
            hour: 0.694,
            month: 500,
            compute: "24x Compute",
            ram: "30 GB RAM",
            category: "DEDICATED COMPUTE",
            subtype: "MEMORY-OPTIMIZED"
        },
        'performance-xl': { 
            hour: 1.04,
            month: 750,
            compute: "50x Compute",
            ram: "62 GB RAM",
            category: "DEDICATED COMPUTE"
        },
        'performance-2xl': { 
            hour: 2.08,
            month: 1500,
            compute: "100x Compute",
            ram: "126 GB RAM",
            category: "DEDICATED COMPUTE"
        }
    },
    dynoCount: {
        additionalDyno: {
            hour: 0.035,
            month: 25
        }
    },
    redisPlans: {
        'mini': { 
            hour: 0.004, 
            month: 3,
            display: "Mini"
        },
        'premium-0': { 
            hour: 0.021, 
            month: 15,
            display: "Premium 0"
        },
        'premium-1': { 
            hour: 0.042, 
            month: 30,
            display: "Premium 1"
        },
        'premium-2': { 
            hour: 0.083, 
            month: 60,
            display: "Premium 2"
        },
        'premium-3': { 
            hour: 0.167, 
            month: 120,
            display: "Premium 3"
        },
        'premium-5': { 
            hour: 0.278, 
            month: 200,
            display: "Premium 5"
        },
        'premium-7': { 
            hour: 1.04, 
            month: 750,
            display: "Premium 7"
        }
    }
 };
 
 export const formatPriceWithDescription = (price, type = PRICE_TYPES.DYNO) => {
    if (!price) return null;
    
    const baseFormat = {
        hourly: `~$${price.hour.toFixed(3)}/hour`,
        monthly: `Max of $${price.month.toFixed(2)}/month`
    };
    
    if (type === PRICE_TYPES.DYNO && price.compute) {
        return {
            ...baseFormat,
            specs: `${price.compute} • ${price.ram}`,
            category: price.category,
            subtype: price.subtype
        };
    }
    
    if (type === PRICE_TYPES.REDIS) {
        return {
            ...baseFormat,
            display: price.display
        };
    }
    
    return baseFormat;
 };
 
 export const formatTotalPrice = (prices) => {
    const total = {
        hour: prices.reduce((sum, price) => sum + (price?.hour || 0), 0),
        month: prices.reduce((sum, price) => sum + (price?.month || 0), 0)
    };
 
    return {
        hourly: `~$${total.hour.toFixed(3)}/hour`,
        monthly: `Max of $${total.month.toFixed(2)}/month`
    };
 };
 
 export const calculateDynoPrice = (dynoCount) => {
    if (dynoCount <= 1) return { hour: 0, month: 0 };
    
    const additionalDynos = dynoCount - 1;
    return {
        hour: additionalDynos * herokuPrices.dynoCount.additionalDyno.hour,
        month: additionalDynos * herokuPrices.dynoCount.additionalDyno.month
    };
 };
 
 export const getDynoPrice = (dynoType) => {
    console.log('Input dynoType:', dynoType);
    const normalizedType = dynoType.toLowerCase().replace(/\s+/g, '-');
    console.log('Normalized dynoType:', normalizedType);
    const price = herokuPrices.dynoType[normalizedType];
    console.log('Found price:', price);
    return price || null;
};
 
 export const getRedisPrice = (planName) => {
    return herokuPrices.redisPlans[planName] || null;
 };
 
 export const getAllPricing = (dynoType, dynoCount, redisPlan) => {
    if (!dynoType || !dynoCount || !redisPlan) return null;
    
    const dynoPricing = getDynoPrice(dynoType);
    const additionalDynoPricing = calculateDynoPrice(dynoCount);
    const redisPricing = getRedisPrice(redisPlan);
    
    console.info(`dynoPricing: ${dynoPricing}`)
    console.info(`additionalDynoPricing: ${additionalDynoPricing}`)
    console.info(`redisPricing: ${redisPricing}`)

    if (!dynoPricing || !redisPricing) return null;
 
    return {
        dyno: formatPriceWithDescription(dynoPricing, PRICE_TYPES.DYNO),
        additional: formatPriceWithDescription(additionalDynoPricing, PRICE_TYPES.DYNO),
        redis: formatPriceWithDescription(redisPricing, PRICE_TYPES.REDIS),
        total: formatTotalPrice([dynoPricing, additionalDynoPricing, redisPricing])
    };
 };