/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ImageGrid from "./BodyImageGrid";
import { fetchData, showAlert } from "../../../../generalUtils";
import { useLoading } from "../../../../Components/Common/LoadingSpinner/LoadingContext";
import { useAppContext } from "../../../../AppContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./BodyGallery.css";
import {
  getBodyImageFromDB,
  saveBodyImageToDB,
  getLastUpdateTime,
  updateLastUpdateTime,
  getAllKeysFromDB,
  deleteImageFromDB,
} from "../../../../IndexedDBUtils";

const BODY_STORE_NAME = "BodyImages";
const BODY_REFRESH_INTERVAL_SECONDS = 120;

const BodyGallery = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const {
    eventName,
    images,
    setImages,
    selectedImages,
    setSelectedImages,
    totalValue,
    setTotalValue,
  } = useAppContext();
  const number = parseInt(sessionStorage.getItem("number")) || 1;
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    const loadImages = async () => {
      const lastUpdateTime = await getLastUpdateTime(BODY_STORE_NAME);
      const currentTime = new Date();
      const timeDifference = (currentTime - new Date(lastUpdateTime)) / 1000;
      if (timeDifference < BODY_REFRESH_INTERVAL_SECONDS) {
        // console.log("Loading images from IndexedDB...");
        await loadImagesFromDB();
      } else {
        // console.log("Checking for updates from server...");
        await fetchDataFromServer();
      }
    };

    const loadImagesFromDB = async () => {
      const dbImages = [];
      const allKeys = await getAllKeysFromDB(BODY_STORE_NAME);

      for (const path of allKeys) {
        const image = await getBodyImageFromDB(path);
        if (image) {
          // console.log(`Image loaded from IndexedDB: ${path}`);
          dbImages.push({ path, url: image });
        }
      }
      setImages(dbImages);

      if (dbImages.length === 0) {
        // console.log("No images found in IndexedDB, fetching from server...");
        fetchDataFromServer();
      }
      hideLoading();
    };

    const fetchDataFromServer = async () => {
      if (eventName) {
        const encodedEventName = encodeURIComponent(eventName);
        showLoading();
        try {
          const data = await fetchData(
            serverUrl,
            "/api/bodies",
            encodedEventName
          );
          // console.log("Paths fetched from server:", data);

          const keysFromDB = await getAllKeysFromDB(BODY_STORE_NAME);

          // Find new paths to add
          const newPaths = data.filter((path) => !keysFromDB.includes(path));

          // Find paths to delete
          const pathsToDelete = keysFromDB.filter(
            (path) => !data.includes(path)
          );

          if (newPaths.length === 0 && pathsToDelete.length === 0) {
            // console.log("No changes in paths, skipping server fetch.");
            await loadImagesFromDB();
            hideLoading();
            updateLastUpdateTime(BODY_STORE_NAME);
            return;
          }

          // Delete images that no longer exist on the server
          const deletePromises = pathsToDelete.map(async (path) => {
            await deleteImageFromDB(BODY_STORE_NAME, path);
            // console.log(`Image deleted from IndexedDB: ${path}`);
          });

          await Promise.all(deletePromises);

          // console.log("New paths found, fetching images from server...");
          const imagePromises = newPaths.map(async (path) => {
            const response = await fetch(`${serverUrl}/image${path}`);
            if (!response.ok) {
              console.error(`Failed to fetch image for path: ${path}`);
              return;
            }
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
              const base64Data = reader.result;
              await saveBodyImageToDB(path, base64Data);
              // console.log(`Image saved to IndexedDB: ${path}`);
            };
          });

          await Promise.all(imagePromises);

          // Update imagePaths with all paths from server
          setImages(
            data.map((path) => ({ path, url: `${serverUrl}/image${path}` }))
          );

          // Update the last update time after fetching new images
          await updateLastUpdateTime(BODY_STORE_NAME);
        } catch (error) {
          console.error("Error fetching bodies:", error);
        } finally {
          hideLoading();
        }
      } else {
        console.error("BodyGallery - eventName is missing.");
      }
    };

    loadImages();
    hideLoading();
  }, [serverUrl, eventName]);

  useEffect(() => {
    // Reset selections when the component is mounted
    setSelectedImages([]);
    setTotalValue(0);
  }, [setSelectedImages, setTotalValue]);

  const toggleImageSelection = (image) => {
    const imageValue = parseInt(image.split("-").pop().split(".")[0][0]); // Get the first digit (tens place)
    const teamCount = parseInt(image.split("-").pop().split(".")[0][1]); // Get the second digit (ones place)
    const message =
      teamCount === 1
        ? "בחרת תמונה שמתאימה רק ליחיד - אנא שנה את בחירתך בעמוד הקודם או תבחר גוף אחר"
        : "בחרת תמונה שמתאימה רק לזוגי - אנא שנה את בחירתך בעמוד הקודם או תבחר גוף אחר";
    if (teamCount < number) {
      showAlert("warn", message, 6000);
      return;
    }

    if (selectedImages.includes(image)) {
      setSelectedImages(selectedImages.filter((img) => img !== image));
      setTotalValue(totalValue - imageValue);
    } else {
      if (totalValue + imageValue <= number) {
        setSelectedImages([...selectedImages, image]);
        setTotalValue(totalValue + imageValue);
      }
    }
  };

  const goNext = () => {
    sessionStorage.setItem("selectedBodies", JSON.stringify(selectedImages));
    showLoading();
    navigate(`/${eventName}/edit-your-character`);
  };

  const goBack = async () => {
    showLoading();
    navigate(`/${eventName}/face-gallery`);
  };

  return (
    <div className="gallery-container">
      <ToastContainer />
      <h1 className="gallery-title rtl">בחרו את הדמות שתרצו</h1>
      <ImageGrid
        images={images}
        selectedImages={selectedImages}
        toggleImageSelection={toggleImageSelection}
        totalValue={totalValue}
        number={number}
        serverUrl={serverUrl}
      />
      <div className="gallery-button-container">
        <button className="gallery-button" onClick={goBack}>
          חזרה
        </button>
        <button
          className={`gallery-button ${
            totalValue === number ? "" : "disabled-button"
          }`}
          onClick={goNext}
          disabled={totalValue !== number}
        >
          <span>הבא </span>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "normal",
            }}
          >
            ({totalValue}/{number})
          </span>
        </button>
      </div>
    </div>
  );
};

export default BodyGallery;
