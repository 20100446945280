/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { loadImages } from "../SaveAndLoad"; // Ensure this is the correct import path

export const useLoadImages = (
  serverUrl,
  stageRef,
  showLoading,
  hideLoading,
  navigate,
  showAlert
) => {
  const [images, setImages] = useState([]);
  const [canvasWidth, setCanvasWidth] = useState("100%");
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  useEffect(() => {
    const loadAndSetImages = async () => {
      showLoading();
      const width = await loadImages(
        stageRef,
        setImages,
        showLoading,
        hideLoading,
        setIsLoadingComplete,
        navigate,
        showAlert
      );
      setCanvasWidth(width);
      hideLoading();
    };

    loadAndSetImages();
  }, [serverUrl, stageRef]);

  return { images, setImages, canvasWidth, isLoadingComplete };
};
