/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  LoadingProvider,
  useLoading,
} from "./Components/Common/LoadingSpinner/LoadingContext";
import LoadingSpinner from "./Components/Common/LoadingSpinner/LoadingSpinner";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import WhatsAppButton from "./Components/Common/WhatsAppButton/WhatsAppButton";
import EventNotFound from "./Pages/NonAppPages/EventNotFound/EventNotFound";
import EventStatusPage from "./Pages/NonAppPages/EventStatusPage/EventStatusPage";
import { fetchData } from "./generalUtils";
import { deleteDatabase } from "./IndexedDBUtils";
import Login from "./Login";

// Swingies Components
import SwingiesHome from "./Pages/AttractionApps/Swingies/Home/Home";
import SwingiesFaceGallery from "./Pages/AttractionApps/Swingies/FaceGallery/FaceGallery";
import SwingiesBodyGallery from "./Pages/AttractionApps/Swingies/BodyGallery/BodyGallery";
import SwingiesEditYourCharacter from "./Pages/AttractionApps/Swingies/EditYourCharacter/EditYourCharacter";
import SwingiesResult from "./Pages/AttractionApps/Swingies/Result/Result";

// MiniMove Components for development
import MiniMoveHome from "./Pages/AttractionApps/MiniMove/Home/Home";
import MiniMoveFaceGallery from "./Pages/AttractionApps/MiniMove/FaceGallery/FaceGallery";
import MiniMoveBodyGallery from "./Pages/AttractionApps/MiniMove/BodyGallery/BodyGallery";
import MiniMoveResult from "./Pages/AttractionApps/MiniMove/Result/Result";

// MiniMove template for temproray
// import MiniMoveHome from "./Pages/AttractionApps/MiniMove-template/Home/Home";
// import MiniMoveFaceGallery from "./Pages/AttractionApps/MiniMove-template/FaceGallery/FaceGallery";
// import MiniMoveBodyGallery from "./Pages/AttractionApps/MiniMove-template/BodyGallery/BodyGallery";
// import MiniMoveResult from "./Pages/AttractionApps/MiniMove-template/Result/Result";

const serverUrl = process.env.REACT_APP_SERVER_URL;

// This function returns the appropriate components for an event based on its name
const getAttractionComponents = (eventName) => {
  // Extract the event type from the event name (e.g., "swingies" or "minimove")
  const eventType = eventName.split("_")[0].toLowerCase();

  // Return the appropriate components based on the event type
  switch (eventType) {
    case "event":
    case "swingies":
      return {
        Home: SwingiesHome,
        FaceGallery: SwingiesFaceGallery,
        BodyGallery: SwingiesBodyGallery,
        EditYourCharacter: SwingiesEditYourCharacter,
        Result: SwingiesResult,
      };
    case "minimove":
      return {
        Home: MiniMoveHome,
        FaceGallery: MiniMoveFaceGallery,
        BodyGallery: MiniMoveBodyGallery,
        Result: MiniMoveResult,
      };
    default:
      return null;
  }
};

const App = () => {
  return (
    <Router>
      <LoadingProvider>
        <EnsureHomeOnReload>
          <Routes>
            {/* Define routes for different event paths */}
            <Route exact path="/:eventName/:page?" element={<EventWrapper />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<AdminPanel />} />
            <Route path="*" element={<EventNotFound />} />
          </Routes>
          {/* Show WhatsApp button on all pages except the admin panel */}
          {window.location.pathname !== "/" && <WhatsAppButton />}
        </EnsureHomeOnReload>
        <LoadingHandler />
      </LoadingProvider>
    </Router>
  );
};

const EventWrapper = () => {
  const [eventData, setEventData] = useState(null); // Holds event data fetched from the server
  const [components, setComponents] = useState(null); // Holds the components for the current event
  const location = useLocation();

  useEffect(() => {
    // Extract event name from the URL path
    const eventName = window.location.pathname.split("/")[1];
    const encodedEventName = encodeURIComponent(eventName);

    // Save the event name in session storage for later use
    if (encodedEventName) {
      sessionStorage.setItem("event_name", encodedEventName);
    }

    // Check if the event exists by making an API call
    const checkEventExists = async () => {
      let data;
      try {
        data = await fetchData(serverUrl, "/api/check-event", encodedEventName);
        console.info(`data: ${data}`);
        if (data.exists) {
          // Set the appropriate components for the current event
          setComponents(getAttractionComponents(eventName));
        }
        setEventData(data);
      } catch (error) {
        console.info(`data: ${data}`);
        console.log("Error checking event:", error);
        setEventData({ exists: false });
      }
    };

    checkEventExists();
  }, []);

  // Show loading spinner while event data is being fetched
  if (!eventData) {
    return <LoadingSpinner />;
  }

  // If the event does not exist, show the EventNotFound page
  if (!eventData.exists) {
    deleteDatabase("ImageDatabase"); // Delete cached images if event not found
    return <EventNotFound />;
  }

  // If the event is not currently running, show the EventStatusPage
  if (eventData.progressStatus !== "running") {
    deleteDatabase("ImageDatabase"); // Delete cached images if event is not running
    return (
      <EventStatusPage
        status={eventData.progressStatus}
        startTime={eventData.startTime}
      />
    );
  }

  // Show loading spinner while components are being set
  if (!components) {
    return <LoadingSpinner />;
  }

  // Destructure the components object to get the specific components for the event
  const {
    Home,
    FaceGallery,
    BodyGallery,
    EditYourCharacter = null,
    Result,
  } = components;

  // Determine which component to render based on the current page
  const page = location.pathname.split("/")[2];
  switch (page) {
    case "face-gallery":
      return <FaceGallery />;
    case "body-gallery":
      return <BodyGallery />;
    case "edit-your-character":
      return EditYourCharacter ? <EditYourCharacter /> : <Home />;
    case "result":
      return <Result />;
    case "status":
      return (
        <EventStatusPage
          status={eventData.progressStatus}
          startTime={eventData.startTime}
        />
      );
    default:
      return <Home />;
  }
};

const EnsureHomeOnReload = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Ensure that on reload, the user is navigated to the correct event page
    const navigationEntries = performance.getEntriesByType("navigation");
    if (
      navigationEntries.length > 0 &&
      navigationEntries[0].type === "reload"
    ) {
      if (location.pathname !== "/") {
        const eventName = location.pathname.split("/")[1];
        navigate(`/${eventName}`);
      }
    }
  }, []);

  return children;
};

const LoadingHandler = () => {
  const { isLoading } = useLoading();
  // Show loading spinner if any component is currently loading
  return isLoading ? <LoadingSpinner /> : null;
};

export default App;
