import React from "react";
import { organizeImages } from "./BodyUtils";
import "./BodyGallery.css";

const ImageGrid = ({
  images,
  selectedImages,
  toggleImageSelection,
  totalValue,
  number,
  serverUrl,
}) => {
  const organizedImages = organizeImages(images, number);

  return (
    <div className="gallery-grid">
      {organizedImages.map((row, rowIndex) => (
        <div key={rowIndex} className="gallery-row">
          {row.map((imageObj, index) => {
            const { path, url } = imageObj;
            const imageValue = parseInt(path.split("-").pop().split(".")[0][0]); // Get the first digit (tens place)
            const isSelected = selectedImages.includes(path);
            const isSelectable =
              totalValue + imageValue <= number || isSelected;
            return (
              <div
                key={index}
                className={`relative gallery-image-container gallery-image-value-${imageValue}`}
                style={{ flex: imageValue }}
              >
                <img
                  src={url || `${serverUrl}/image${path}`}
                  alt={`Body ${index}`}
                  className={`gallery-image ${
                    isSelected ? "gallery-image-selected" : ""
                  } ${
                    !isSelectable && !isSelected
                      ? "gallery-image-unselectable"
                      : ""
                  }`}
                  onClick={() => toggleImageSelection(path)}
                />
                {isSelected && (
                  <div className="gallery-image-overlay">
                    <span className="text-white text-2xl">✔</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;
