/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./EventStatusPage.css";

const EventStatusPage = ({ status, startTime }) => {
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [initialTotalTime, setInitialTotalTime] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [error, setError] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(0);

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    if (status === "waiting") {
      const eventDate = parseDate(startTime);
      setEventDate(eventDate);
      const now = new Date();
      const difference = eventDate - now;

      if (difference > 0) {
        const totalSeconds = Math.floor(difference / 1000);
        setInitialTotalTime(totalSeconds);
        setTimeRemaining(totalSeconds);
        setDaysRemaining(Math.floor(totalSeconds / (3600 * 24)));
      }

      const interval = setInterval(() => {
        updateTimeRemaining(eventDate, interval);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [status, startTime]);

  const updateTimeRemaining = (eventDate, interval) => {
    const now = new Date();
    const difference = eventDate - now;

    if (difference <= 0) {
      clearInterval(interval);
      setTimeRemaining(0);
      handleRefresh();
    } else {
      const totalSeconds = Math.floor(difference / 1000);
      setTimeRemaining(totalSeconds);
      setDaysRemaining(Math.floor(totalSeconds / (3600 * 24)));
    }
  };

  const handleRefresh = async (retry = false) => {
    try {
      const response = await fetch(`${serverUrl}/api/update-events-status`, {
        method: "POST",
      });

      if (response.ok) {
        window.location.reload();
      } else {
        throw new Error("Failed to refresh events status");
      }
    } catch (error) {
      console.error("Error refreshing events status:", error);
      if (!retry) {
        setTimeout(() => handleRefresh(true), 5000);
      } else {
        setError(true);
      }
    }
  };

  const parseDate = (dateString) => {
    const [day, month, yearAndTime] = dateString.split("/");
    const [year, time] = yearAndTime.split(" ");
    const [hours, minutes] = time.split(":");

    const date = new Date(year, month - 1, day, hours, minutes);
    return new Date(
      date.toLocaleString("en-US", { timeZone: "Asia/Jerusalem" })
    );
  };

  const adjustedSeconds = timeRemaining - daysRemaining * 86400;
  const hours = Math.floor(adjustedSeconds / 3600);
  const minutes = Math.floor((adjustedSeconds % 3600) / 60);
  const seconds = adjustedSeconds % 60;

  return (
    <div className="event-status-page rtl">
      {status === "waiting" && (
        <>
          <h1 className="event-status-header">
            {timeRemaining <= 0
              ? "האירוע התחיל! רענן את הדף"
              : "האירוע עדיין לא התחיל"}
          </h1>
          <h1 className="event-status-header2">
            {timeRemaining <= 0 ? "" : "⏳🥱"}
          </h1>
          {eventDate && (
            <h2 className="event-status-subheader">
              האירוע יתחיל ב
              {`${eventDate.getDate().toString().padStart(2, "0")}/${(
                eventDate.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}/${eventDate.getFullYear()} ${eventDate
                .getHours()
                .toString()
                .padStart(2, "0")}:${eventDate
                .getMinutes()
                .toString()
                .padStart(2, "0")}`}
            </h2>
          )}
          {daysRemaining > 0 && (
            <h2 className="days-remaining">נותרו {daysRemaining} ימים ו-</h2>
          )}
          {timeRemaining !== null && (
            <div className="countdown-timer">
              <CircularProgressbar
                value={timeRemaining}
                maxValue={initialTotalTime}
                text={`${hours.toString().padStart(2, "0")}:${minutes
                  .toString()
                  .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`}
                styles={buildStyles({
                  pathColor: `rgba(62, 152, 199, ${
                    timeRemaining / initialTotalTime
                  })`,
                  textColor: "#f88",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
            </div>
          )}
        </>
      )}
      {status === "pass" && (
        <h1 className="event-status-header">האירוע נגמר, מקווים שנהנתם.</h1>
      )}
      {error && (
        <div className="error-message">
          <h1>האירוע התחיל</h1>
          <p>המתן כדקה ונסה לרענן את הדף בשנית</p>
        </div>
      )}
    </div>
  );
};

export default EventStatusPage;
