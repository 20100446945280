import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Result.css";

const MiniMoveResult = () => {
  const navigate = useNavigate();
  const { eventName } = useParams();

  const goToNextPage = () => {
    navigate(`/${eventName}`);
  };

  return (
    <div className="simple-container">
      <h1 className="title">תוצאה סופית - MiniMove</h1>
      <button className="next-button" onClick={goToNextPage}>
        חזרה להתחלה
      </button>
    </div>
  );
};

export default MiniMoveResult;
