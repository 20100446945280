export const fetchEvents = (url, setEvents) => {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(async (data) => {
      try {
        await setEvents(data.events);
      } catch (e) {
        throw e;
      }
    })
    .catch((error) => {
      console.error("Error fetching events:", error);
    });
};

export const parseEventName = (folderName) => {
  const parts = folderName.split("_");
  if (parts.length !== 3) {
    throw new Error(`Invalid folder name format: ${folderName}`);
  }

  const [prefix, name, date] = parts;
  const dateParts = date.split("-").map(Number);
  if (dateParts.length !== 3) {
    throw new Error(`Invalid date format in folder name: ${date}`);
  }

  const [day, month, year] = dateParts;
  return {
    prefix,
    name,
    date: new Date(year, month - 1, day),
  };
};

export const checkEventReady = async (serverUrl, eventName, eventDate) => {
  const fullEventName = `event_${eventName}_${eventDate}`;
  const encodedEventName = encodeURIComponent(fullEventName);
  try {
    const response = await fetch(
      `${serverUrl}/api/event-ready?event_name=${encodedEventName}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error checking event readiness:", error);
    throw error;
  }
};

export const parseDateString = (dateString) => {
  const [date, time] = dateString.split(" ");
  const [day, month, year] = date.split("/");
  const [hours, minutes] = time.split(":");
  return new Date(year, month - 1, day, hours, minutes);
};
